import { getMsalInstance } from '@features/auth';
import { executeTokenAdquisition } from '@features/auth/api';
import { useStore } from '@store';
import { produce } from 'immer';

const http = async <T>(path: string, config: RequestInit): Promise<T> => {
    const idToken = useStore.getState().auth.user.accessToken;

    const secureConfig = idToken
        ? produce(config, draft => {
              draft.headers = { ...draft.headers, Authorization: `Bearer ${idToken}` };
          })
        : config;

    const request = new Request(path, secureConfig);
    const response = await fetch(request);

    if (!response.ok) {
        switch (response.status) {
            case 400:
                throw new Error('badRequest', {
                    cause: response.json(),
                });
            case 401: {
                if (response.headers.get('www-authenticate')?.includes('The token expired')) {
                    const resultRefresh = await refreshToken();
                    if (resultRefresh !== null) {
                        const result = await http<T>(path, config);
                        return result;
                    }
                } else {
                    try {
                        const detail = (await response.json()) as string;
                        if (detail?.includes('expired')) {
                            const resultRefresh = await refreshToken();
                            if (resultRefresh !== null) {
                                const result = await http<T>(path, config);
                                return result;
                            }
                        }
                    } catch {
                        throw new Error(response.statusText, {
                            cause: response.status,
                        });
                    }
                }
                throw new Error(response.statusText, {
                    cause: response.status,
                });
            }
            case 403:
                window.location.href = '/error?error=forbidden';
                break;
            case 404:
                window.location.href = '/error?error=notFound';
                break;
            case 409:
                throw new Error('conflict', {
                    cause: response.status,
                });
            default:
                window.location.href = '/error';
                break;
        }
    }

    const contentType = response.headers.get('Content-Type');
    if (contentType?.includes('application/json')) {
        return response.json() as Promise<T>;
    } else if (contentType?.includes('image/')) {
        return response.blob() as Promise<T>;
    } else {
        return response.text() as Promise<T>;
    }
};

const defaultConfig: RequestInit = {
    headers: {
        'Content-Type': 'application/json',
    },
};
const blobConfig: RequestInit = {
    headers: {
        'Content-Type': 'image/png',
    },
};

const refreshToken = async () => {
    const authSettings = useStore.getState().auth.settings;
    const setToken = useStore.getState().auth.setToken;
    const instance = getMsalInstance(authSettings);
    await instance.initialize();
    const result = await executeTokenAdquisition(instance, authSettings.scopes);
    result !== null && setToken(result?.accessToken || '');

    return result;
};

type Agent = {
    get: <T>(path: string, signal?: AbortSignal, customConfig?: RequestInit) => Promise<T>;
    post: <T, U>(path: string, body?: T, signal?: AbortSignal) => Promise<U>;
    postWithoutBody: <U>(path: string, signal?: AbortSignal) => Promise<U>;
    postFormData: <T, U>(path: string, body: T, signal?: AbortSignal) => Promise<U>;
    put: <T, U>(path: string, body: T, signal?: AbortSignal) => Promise<U>;
    delete: <T>(path: string, signal?: AbortSignal) => Promise<T>;
    patch: <T, U>(path: string, body?: T, signal?: AbortSignal) => Promise<U>;
};

const wait = (duration: number) => {
    return new Promise(resolve => setTimeout(resolve, duration));
};

const getUrl = (apiUrl: string, path: string) => `${apiUrl}${path}`;

export { blobConfig, defaultConfig, getUrl, http, wait, type Agent };
