import { produce } from 'immer';
import { StateCreator } from 'zustand';
import { AuthSettings } from './configuration';
import { User } from './domain';

export type Slice = {
    auth: {
        setUser: (user: User) => void;
        user: User;
        setToken: (token: string) => void;
        settings: AuthSettings;
        setAuthSettings: (settings: AuthSettings) => void;
    };
};

export const createSlice: StateCreator<Slice, [], [], Slice> = set => ({
    auth: {
        user: {
            account: null,
            accessToken: '',
            authRequest: { scopes: [] },
            image: '',
            permissions: [],
            email: '',
            name: '',
        },
        settings: {
            clientId: '',
            authority: '',
            redirectUri: '',
            postLogoutRedirectUri: '',
            scopes: [],
        },
        setUser: (user: User) =>
            set(
                produce((draft: Slice) => {
                    draft.auth.user = user;
                })
            ),
        setToken: (token: string) =>
            set(
                produce((draft: Slice) => {
                    draft.auth.user.accessToken = token;
                })
            ),
        setAuthSettings: (settings: AuthSettings) =>
            set(
                produce((draft: Slice) => {
                    draft.auth.settings.authority = settings.authority;
                    draft.auth.settings.clientId = settings.clientId;
                    draft.auth.settings.postLogoutRedirectUri = settings.postLogoutRedirectUri;
                    draft.auth.settings.scopes = settings.scopes;
                    draft.auth.settings.redirectUri = settings.redirectUri;
                })
            ),
    },
});
