import { useStore } from '@store';
import { Agent, defaultConfig, getUrl, http } from './domain';

const agentApi: Agent = {
    get: <T>(path: string, signal?: AbortSignal, customConfig?: RequestInit): Promise<T> => {
        const config: RequestInit  = customConfig ? { ...customConfig } : { ...defaultConfig };
        return http<T>(getUrl(useStore.getState().settings.apiUrl, path), { method: 'GET', ...config, signal: signal })
    },
    post: <T, U>(path: string, body: T, signal?: AbortSignal): Promise<U> =>
        http<U>(getUrl(useStore.getState().settings.apiUrl, path), {
            method: 'POST',
            ...defaultConfig,
            body: JSON.stringify(body),
            signal: signal,
        }),
    postWithoutBody: <U>(path: string, signal?: AbortSignal): Promise<U> =>
        http<U>(getUrl(useStore.getState().settings.apiUrl, path), {
            method: 'POST',
            ...defaultConfig,
            signal: signal,
        }),
    postFormData: <T, U>(path: string, body: T, signal?: AbortSignal): Promise<U> =>
        http<U>(getUrl(useStore.getState().settings.apiUrl, path), {
            method: 'POST',
            ...defaultConfig,
            body: body as BodyInit,
            signal: signal,
            headers: {},
        }),
    put: <T, U>(path: string, body: T, signal?: AbortSignal): Promise<U> =>
        http<U>(getUrl(useStore.getState().settings.apiUrl, path), {
            method: 'PUT',
            body: JSON.stringify(body),
            ...defaultConfig,
            signal: signal,
        }),
    delete: <T>(path: string, signal?: AbortSignal): Promise<T> =>
        http<T>(getUrl(useStore.getState().settings.apiUrl, path), {
            method: 'DELETE',
            ...defaultConfig,
            signal: signal,
        }),
    patch: <T, U>(path: string, body?: T, signal?: AbortSignal): Promise<U> =>
        http<U>(getUrl(useStore.getState().settings.apiUrl, path), {
            ...defaultConfig,
            method: 'PATCH',
            body: body ? JSON.stringify(body) : JSON.stringify({}),
            signal: signal,
        }),
};

export { agentApi };
